import React from 'react'

import Layout from '@components/layout/Layout'
import ReferenceComponent from '@components/references/reference.component'
import chessImg from '@assets/images/graphic-chess.svg'
import ReadNext from '@components/read-next-button/read-next-button'
import LearnMoreComponent from '@components/learn-more/learn-more.component'

import './recognizing-symptoms.scss'

export const anchors = {
  excessiveSleepiness: 'excessive-daytime-sleepiness',
  cataplexy: 'cataplexy',
  hallucinations: 'hallucinations',
  paralysis: 'paralysis',
  disruption: 'disruption',
}

export const references = [
  {
    content: 'Pelayo R, Lopes MC. Narcolepsy. In: Lee-Chiong TL, ed. ',
    source: 'Sleep: A Comprehensive Handbook.',
    info: 'Hoboken, NJ: Wiley and Sons, Inc.; 2006:145-149.',
  },
  {
    content: 'American Academy of Sleep Medicine. Central disorders of hypersomnolence. In:',
    source: 'The International Classification of Sleep Disorders – Third Edition (ICSD-3) Online Version.',
    info: 'Darien, IL: American Academy of Sleep Medicine; 2014.',
  },
  {
    content: 'Ahmed I, Thorpy M. Clinical features, diagnosis and treatment of narcolepsy.',
    source: 'Clin Chest Med.',
    info: '2010;31(2):371-381.',
  },
  {
    content: 'Thorpy MJ, Krieger AC. Delayed diagnosis of narcolepsy: characterization and impact.',
    source: 'Sleep Med.',
    info: '2014;15(5):502-507.',
  },
  {
    content: 'Black J, Reaven NL, Funk SE, et al. Medical comorbidity in narcolepsy: findings from the Burden of Narcolepsy Disease (BOND) study. ',
    source: 'Sleep Med. ',
    info: '2017;33:13-18. ',
  },
  {
    content: 'Ohayon MM. Narcolepsy is complicated by high medical and psychiatric comorbidities: a comparison with the general population. ',
    source: 'Sleep Med. ',
    info: '2013;14(6):488-492.',
  },
  {
    content: 'Jennum P, Ibsen R, Knudsen S, Kjellberg J. Comorbidity and mortality of narcolepsy: a controlled retro- and prospective national study. ',
    source: 'Sleep. ',
    info: '2013;36(6):835-840.',
  },
  {
    content: 'Cohen A, Mandrekar J, St Louis EK, Silber MH, Kotagal S. Comorbidities in a community sample of narcolepsy. ',
    source: 'Sleep Med. ',
    info: '2018;43:14-18. ',
  },
  {
    content: 'Cheung J, Ruoff CM. Central nervous system hypersomnias. In: ',
    source: 'Sleep and Neurologic Disease.',
    info: '2017',
  },
  {
    content:
      'Overeem S, van Nues SJ, van der Zande WL, et al. The clinical features of cataplexy: a questionnaire study in narcolepsy patients with and without hypocretin-1 deficiency.',
    source: 'Sleep Med.',
    info: '2011;12(1):283-290.',
  },
  {
    content: 'Roth T, Dauvilliers Y, Mignot E, et al. Disrupted nighttime sleep in narcolepsy. J Clin',
    source: 'Sleep Med.',
    info: '2013;9(9):955-965.',
  },
  {
    content:
      'Thorpy MJ, Dauvilliers Y. Clinical and practical considerations in the pharmacologic management of narcolepsy. ',
    source: 'Sleep Med. ',
    info: '2015;16(1):9-18.',
  },
  {
    content:
      'Dauvilliers Y, Lopez R. Parasomnias in narcolepsy with cataplexy. In: Baumann CR, Bassetti CL, Scammell TE, eds. ',
    source: 'Narcolepsy: Pathophysiology, Diagnosis, and Treatment.',
    info: 'New York, NY: Springer Science+Business Media; 2011:291-300.',
  },
  {
    content: 'Scammell TE. The neurobiology, diagnosis, and treatment of narcolepsy.',
    source: 'Ann Neurol.',
    info: '2003;53(2):154-166.',
  },
  {
    content: 'Saper CB, Scammell TE, Lu J. Hypothalamic regulation of sleep and circadian rhythms.',
    source: 'Nature.',
    info: '2005;437(7063):1257-1263.',
  },
]

const RecognizingSymptoms = ({ location }) => {

  const learnMoreContent = {
    className: 'learn-more-wrapper-gray special-padding',
    title: 'The burden of narcolepsy goes\nbeyond symptoms',
    supText: '5-8',
    link: '/comorbidities-risk/cardiovascular-comorbidities/',
    linkLabel: 'Explore comorbidities',
    tracking: 'identifying narcolepsy,	click, identifyingnarcolepsy-cta-comorbidities',
    additionalStyles: 'cta-additional-styles',
    spacingStyles: 'cta-spacing'
  }

  const readNext = {
    readNextContent: 'Screening for narcolepsy',
    goTo: '/screening-and-diagnosis/screeners/',
    tracking: 'identifying narcolepsy,	click,	symptom screeners - identifying narcolepsy'
  }

  return (
    <Layout addedClass='page-recognizing-symptoms' location={location}>
      <div className="recognizing-page-header">
        <div className="primary-container">
          <div className="recognizing-header-text">
            <h1 className="main-page-header">Identifying Narcolepsy Symptoms Is Important </h1>
            <div className="body-copy-large">
              There are 5 main symptoms of narcolepsy, referred to by the acronym CHESS (<b>C</b>ataplexy, <b>H</b>
              allucinations,&nbsp;
              <b>E</b>xcessive daytime sleepiness, <b>S</b>leep paralysis, <b>S</b>leep disruption).<sup>1</sup> While
              all patients with narcolepsy experience excessive daytime sleepiness, they may not experience all 5
              symptoms.<sup>2,3</sup> Looking deeper for these symptoms during the clinical interview may help identify
              patients with narcolepsy.<sup>2,4</sup>
            </div>
          </div>
        </div>
        <div
          className="recognizing-symptoms-image-container"
          role="img"
          aria-label="Recognizing the symptoms of narcolepsy"
          alt="Clouds young man walking rocky terrain carrying boulders on back"
        />
      </div>
      <div className="section-background">
        <div className="primary-container">
          <div className="main-section">
            <div className="main-section-header">
              <h2 className="main-header-title symptoms-title-styles">The 5 Main Symptoms of Narcolepsy</h2>
              <LearnMoreComponent learnMoreContent={learnMoreContent} />
            </div>
            <div className="info-image-wrapper">
              <div className="info-section">
                <div className="info-block">
                  <div className="info-section-header">Cataplexy</div>
                  <div className="info-section-subheader">
                    Estimated to be present in 65%-75% of patients with narcolepsy<sup>9</sup>
                  </div>
                  <div className="info-section-content">
                    This most specific symptom of narcolepsy type 1, is the sudden, generally brief (&lt;2 minutes) loss
                    of muscle tone with retained consciousness. It is usually triggered by strong emotions, such as
                    laughter, surprise, or anger.<sup>2,3,10</sup>
                  </div>
                </div>
                <div className="info-block">
                  <div className="info-section-header">
                    Hallucinations<span className="minor-header"> (hypnagogic and/or hypnopompic)</span>
                  </div>
                  <div className="info-section-subheader">
                    May affect 33%-80% of patients with narcolepsy<sup>11</sup>
                  </div>
                  <div className="info-section-content">
                    Hypnagogic hallucinations are vivid dreamlike experiences that occur while falling asleep. When they
                    happen while waking up, they are called hypnopompic hallucinations.<sup>2,3</sup> These events may
                    occur with sleep paralysis.<sup>3</sup>
                  </div>
                </div>
                <div className="info-block">
                  <div className="info-section-header">Excessive daytime sleepiness</div>
                  <div className="info-section-subheader">
                    Affects 100% of patients with narcolepsy<sup>9,11,12</sup>
                  </div>
                  <div className="info-section-content">
                    Excessive daytime sleepiness is the inability to stay awake and alert during the day, resulting in
                    periods of an irrepressible need for sleep or unintended lapses into drowsiness or sleep.
                    <sup>2,3</sup> Excessive daytime sleepiness is the cardinal symptom of narcolepsy and often the most
                    disabling.<sup>2 </sup>
                  </div>
                </div>
                <div className="info-block">
                  <div className="info-section-header">Sleep paralysis</div>
                  <div className="info-section-subheader">
                    May affect 25%-50% of patients with narcolepsy<sup>11</sup>
                  </div>
                  <div className="info-section-content">
                    Sleep paralysis is the disturbing, temporary inability to move voluntary muscles or speak during
                    sleep-wake transitions. It is often accompanied by hypnagogic or hypnopompic hallucinations.
                    <sup>1-3,13</sup>
                  </div>
                </div>
                <div className="info-block">
                  <div className="info-section-header">Sleep disruption</div>
                  <div className="info-section-subheader">
                    Estimated to affect 30%-95% of patients with narcolepsy<sup>11</sup>
                  </div>
                  <div className="info-section-content">
                    Sleep disruption (also known as disrupted nighttime sleep) is due to frequent awakenings, resulting
                    in poor quality sleep.<sup>2,3,11</sup> Because narcolepsy is a disorder of sleep-wake state
                    instability,<sup>14,15</sup> many patients commonly report disruption of nighttime sleep.
                    <sup>2,11</sup>
                  </div>
                </div>
              </div>
              <div className="info-image-section">
                <img className="chess-image" src={chessImg} alt="Vertical connection of 5 hexagons listing main narcolepsy symptoms spells CHESS"/>
              </div>
            </div>
          </div>
          <ReadNext readNext={readNext} />
        </div>
      </div>

      <ReferenceComponent referenceList={references} />
    </Layout>
  )
}

export default RecognizingSymptoms
