import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SiteLink from '@components/SiteLink'
import './learn-more.component.scss'

// if you are putting this component on a white background use className 'learn-more-wrapper-white' if you are putting this component on a gray background use className 'learn-more-wrapper-gray' this will make sure you have the correct borders and spacing.

const LearnMoreComponent = props => {

  const externalLinkHandler = event => {
    // user can override default behavior
    if(props.externalLinkHandler){
      props.externalLinkHandler(props.learnMoreContent.link, event);
      return;
    }

    // default: redirect the user to the provided site
    if(props.learnMoreContent.link){
      window.location = props.learnMoreContent.link;
      return;
    }
  }

  return (
    <div className={props.learnMoreContent.className}>
      <div className={props.learnMoreContent.spacingStyles ? `learn-more-title ${props.learnMoreContent.spacingStyles}` : 'learn-more-title'}>
        {props.learnMoreContent.title}
        {props.learnMoreContent.supText && <sup>{props.learnMoreContent.supText}</sup>}
      </div>
      <div>
        {
          props.learnMoreContent.externalLink 
          ? (
            <div className="learn-more-external-link" onClick={externalLinkHandler}>
              {props.learnMoreContent.linkLabel || 'Learn more'} 
              <FontAwesomeIcon className="arrow-right" icon="angle-right" />
            </div>
          )
          : (
            <SiteLink
              tracking={props.learnMoreContent.tracking}
              className={props.learnMoreContent.additionalStyles ? `learn-more-link ${props.learnMoreContent.additionalStyles}` : 'learn-more-link'}
              to={props.learnMoreContent.link}
            >
              <div>{props.learnMoreContent.linkLabel || 'Learn more'}</div>
              <FontAwesomeIcon className="arrow-right" icon="angle-right" />
            </SiteLink>
          )
        }
        
      </div>
      {props.learnMoreContent.otherLink && (
        <div>
          <SiteLink
            tracking={props.learnMoreContent.tracking2}
            className={props.learnMoreContent.otherLinkClass || 'learn-more-other-link'}
            to={props.learnMoreContent.otherLink}
          >
            <div>{props.learnMoreContent.otherLinkLabel}</div>
            {props.learnMoreContent.otherLinkArrow && <FontAwesomeIcon className="arrow-right" icon="angle-right" />}
          </SiteLink>
        </div>
      )}
    </div>
  )
}

LearnMoreComponent.propTypes = {
  learnMoreContent: PropTypes.object.isRequired, 
  externalLinkHandler: PropTypes.func 
}

export default LearnMoreComponent
