import React, { Component } from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Layout from '@components/layout/Layout'
import BrightCoveVideoComponent from '@components/brightcove-video/brightcove-video.component'
import ReferencesComponent from '@components/references/reference.component'
import SiteLink from '@components/SiteLink'
import ReadNext from '@components/read-next-button/read-next-button'
import LearnMoreComponent from '@components/learn-more/learn-more.component'

import { videos } from '@components/video-library/videos'
import { anchors } from '../../screening-and-diagnosis/recognizing-symptoms/index'
import { trackGa } from '@components/GoogleTracking'

import './pathophysiology.scss'

class Pathophysiology extends Component {
  constructor(props) {
    super(props)

    this.state = {
      narcoTypeOneSelected: true,
    }
  }
  render() {
    const { pathophysiologyOfNarcolepsy } = videos

    const learnMoreContent = {
      className: 'learn-more-wrapper-gray',
      title: 'Hypocretin deficiency may partially explain certain comorbidities',
      supText: '3-6',
      link: '/comorbidities-risk/cardiovascular-comorbidities/',
      linkLabel: 'Find out which ones',
      tracking: 'pathophysiology,	click, pathophysiology-cta-comorbidities',
      additionalStyles: 'cta-additional-styles',
      spacingStyles: 'patho-spacing'
    }

    const readNext = {
      readNextContent: 'Identifying narcolepsy',
      goTo: '/screening-and-diagnosis/recognizing-symptoms/',
      tracking: 'pathophysiology,	click, identifying narcolepsy - pathophysiology',
    }

    const references = [
      {
        content: 'España RA, Scammell TE. Sleep neurobiology from a clinical perspective.',
        source: 'Sleep.',
        info: '2011;34(7):845-858.',
      },
      {
        content: 'Scammell TE. The neurobiology, diagnosis, and 	treatment of narcolepsy.',
        source: 'Ann Neurol.',
        info: '2003;53(2):154-166.',
      },
      {
        content: 'American Academy of Sleep Medicine. Central disorders of hypersomnolence. In:',
        source: 'The International Classification of Sleep Disorders – Third Edition (ICSD-3) Online Version.',
        info: 'Darien, IL: American Academy of Sleep Medicine; 2014.',
      },
      {
        content: 'McAlpine CS, Kiss MG, Rattik S, et al. Sleep modulates haematopoiesis and protects against atherosclerosis. ',
        source: 'Nature. ',
        info: '2019;566(7744):383-387.',
      },
      {
        content: 'Dauvilliers Y, Jaussent I, Krams B, et al. Non-dipping blood pressure profile in narcolepsy with cataplexy. ',
        source: 'PLoS One. ',
        info: '2012;7(6):e38977.',
      },
      {
        content: 'Grimaldi D, Calandra-Buonaura G, Provini F, et al. Abnormal sleep-cardiovascular system interaction in narcolepsy with cataplexy: effects of hypocretin deficiency in humans. ',
        source: 'Sleep. ',
        info: '2012:35(4):519-528.',
      },
      { 
        content: 'Scammell TE. Narcolepsy.', 
        source: 'N Engl J Med.', 
        info: '2015;373(27):2654-2662.' 
      },
      {
        content:
          'Alakuijala A, Sarkanen T, Partinen M. Hypocretin-1 levels associate with fragmented sleep in patients with narcolepsy type 1.',
        source: 'Sleep.',
        info: '2016;39(5):1047-1050.',
      },
      {
        content:
          'Andlauer O, Moore H 4th, Hong SC, et al. Predictors of hypocretin (orexin) deficiency in narcolepsy without cataplexy.',
        source: 'Sleep.',
        info: '2012;35(9):1247-1255F.',
      },
      {
        content: 'Ahmed I, Thorpy M. Clinical features, diagnosis and treatment of narcolepsy.',
        source: 'Clin Chest Med.',
        info: '2010;31(2):371-381.',
      },
    ]

    return (
      <Layout location={this.props.location} addedClass='page-pathophysiology'>
        <div className="pathophysiology-component-wrapper">
          <div
            id="background-wrapper"
            className="page-background-wrapper"
            role="img"
            aria-label="Pathophysiology of Narcolepsy"
            alt="Cloudy open terrain path with boulders"
          >
            <div className="primary-container">
              <h1 className="hero-title">Pathophysiology of Narcolepsy</h1>
              <div className="hero-comment">
                Various neuronal systems are involved in the regulation of normal sleep/wake states.
                <span className="super">1</span>{' '}
                Narcolepsy occurs when these neurons do not interact properly, resulting in a dysregulation of the
                sleep/wake states. This allows elements of the sleep state to intrude on wakefulness and vice versa.
                <span className="super">1,2</span>
              </div>
            </div>
          </div>

          <div className="main-content-background">
            <div className="card-wrapper">
              <div className="main-content">
                <div className="content">
                  <div className="pathophysiology-header">
                    <h2>Neurobiology of Normal Wakefulness</h2>
                    <LearnMoreComponent learnMoreContent={learnMoreContent} />
                  </div>
                  <p className="body-copy">
                    Interconnected wake-promoting neurons activate cortical and subcortical arousal regions and inhibit
                    neurons primarily responsible for promoting rapid eye-movement (REM) sleep and non-REM (NREM) sleep.
                    <sup>1,2</sup> The neuropeptide hypocretin (or orexin) activates these wake-promoting neurons to help
                    maintain wakefulness and muscle tone and inhibit REM sleep during the day.
                    <sup>1,7</sup>
                  </p>
                  <h3 className="card-subheader">Narcolepsy: A Deeper Look</h3>
                  <div
                    className="video"
                    role="image"
                    aria-label="Video on the pathophysiology of narcolepsy and the neurobiology of normal wakefulness"
                  >
                    <BrightCoveVideoComponent
                      player={pathophysiologyOfNarcolepsy.player}
                      account={pathophysiologyOfNarcolepsy.account}
                      videoId={pathophysiologyOfNarcolepsy.videoId}
                      tracking={pathophysiologyOfNarcolepsy.tracking}
                      trackPercentComplete={pathophysiologyOfNarcolepsy.trackPercentComplete}
                      src={pathophysiologyOfNarcolepsy.src}
                    />
                  </div>
                  <div className="video-content-desktop">
                    <div className="header-column">
                      <div className="header-wrapper">
                        <h3
                          className={this.isNarcoTypeOneSelected(this.state.narcoTypeOneSelected)}
                          onClick={() => {
                            !this.state.narcoTypeOneSelected &&
                              trackGa('pathophysiology, click, narcolepsy type 1* - pathophysiology')
                            this.setState({ narcoTypeOneSelected: true })
                          }}
                        >
                          Narcolepsy type 1*(narcolepsy with cataplexy)
                        </h3>
                        <FontAwesomeIcon
                          className={this.isNarcoArrowSelected(this.state.narcoTypeOneSelected)}
                          icon="angle-right"
                        />
                      </div>
                      <div className="header-wrapper">
                        <h3
                          className={this.isNarcoTypeOneSelected(!this.state.narcoTypeOneSelected)}
                          onClick={() => {
                            this.state.narcoTypeOneSelected &&
                              trackGa('pathophysiology, click, narcolepsy type 2* - pathophysiology')
                            this.setState({ narcoTypeOneSelected: false })
                          }}
                        >
                          Narcolepsy type 2 (narcolepsy without cataplexy)
                        </h3>
                        <FontAwesomeIcon
                          className={this.isNarcoArrowSelected(!this.state.narcoTypeOneSelected)}
                          icon="angle-right"
                        />
                      </div>
                    </div>
                    <div className="type-content">
                      <div className={this.isNarcoContentSelected(this.state.narcoTypeOneSelected)}>
                        <p>
                          Narcolepsy type 1 is thought to be due to the permanent loss of hypocretin neurons. Patients
                          with narcolepsy type 1 have low or undetectable levels of cerebrospinal fluid (CSF)
                          hypocretin-1.
                          <sup>3,†</sup>
                        </p>
                        <p>
                          Based on animal models, loss of hypocretin neurons is thought to lead to inconsistent signaling
                          of wake-promoting neurons responsible for maintaining wakefulness and muscle tone and inhibit
                          NREM and REM-sleep promoting neurons (eg, dopaminergic, noradrenergic, serotonergic neurons).
                          <sup>1,7,‡</sup>
                        </p>
                        <ul>
                          <li className="narco-one-list-item">
                            <span>
                              Wake-promoting neurons fail to activate cortical and subcortical arousal regions and fail to
                              inhibit sleep-promoting neurons (ie, GABAergic neurons), resulting in{' '}
                              <Link
                                to={'/resources/glossary-of-terms/excessive-daytime-sleepiness/'}
                                className="link"
                                onClick={() => trackGa('neurobiology of wakefulness, click, ess link - neurobiology')}
                              >
                                excessive daytime sleepiness.
                              </Link>
                              <sup>7</sup>
                            </span>
                          </li>
                          <li className="narco-one-list-item">
                            <span>
                              Certain wake-promoting neurons that also suppress REM signaling fail to inhibit
                              REM-promoting neurons, resulting in{' '}
                              <Link
                                to={'/resources/glossary-of-terms/cataplexy/'}
                                className="link"
                                onClick={() => trackGa('neurobiology of wakefulness, click, cataplexy link')}
                              >
                                cataplexy
                              </Link>{' '}
                              and other abnormal manifestations of REM sleep such as{' '}
                              <Link
                                to={'/resources/glossary-of-terms/hypnagogic-hypnopompic-hallucinations/'}
                                className="link"
                                onClick={() =>
                                  trackGa('neurobiology of wakefulness, click, hypnagogic/hypnopompic halluciations link')
                                }
                              >
                                hypnagogic/hypnopompic hallucinations
                              </Link>{' '}
                              and{' '}
                              <Link
                                to={'/resources/glossary-of-terms/sleep-paralysis/'}
                                className="link"
                                onClick={() => trackGa('neurobiology of wakefulness, click, sleep paralysis link')}
                              >
                                sleep paralysis.
                              </Link>
                              <sup>2,7</sup>
                            </span>
                          </li>
                          <li className="narco-one-list-item">
                            <span>
                              Disruption of mutually inhibitory sleep-wake circuits may also lead to unwanted transitions
                              from sleep to wakefulness and to associated{' '}
                              <Link
                                to={'/resources/glossary-of-terms/sleep-disruption/'}
                                className="link"
                                onClick={() => trackGa('neurobiology of wakefulness, click, sleep disruption link')}
                              >
                                sleep disruption.
                              </Link>
                              <sup>2,8</sup>
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className={this.isNarcoContentSelected(!this.state.narcoTypeOneSelected)}>
                        Narcolepsy type 2 is likely a heterogeneous disorder.<sup>3</sup> In patients with narcolepsy type
                        2, cataplexy is not present, and CSF hypocretin-1 levels are usually normal or unknown.
                        <sup>3</sup> One quarter to one third of patients diagnosed with narcolepsy type 2 may actually
                        have hypocretin deficiency, which would classify them as narcolepsy type 1 if levels were known,
                        and about 8% may have intermediate levels of CSF hypocretin-1.<sup>3,9</sup> These patients are
                        more likely to develop cataplexy over time than those with normal hypocretin levels.
                        <sup>9</sup> The exact cause of narcolepsy type 2 is unknown.
                        <sup>3</sup>
                      </div>
                    </div>
                  </div>
                  <div className="video-content">
                    <h3 className="narco-type">Narcolepsy type 1*(narcolepsy with cataplexy) </h3>
                    <div className="narco-type-content">
                      <p>
                        Narcolepsy type 1 is thought to be due to the permanent loss of hypocretin neurons. Patients with
                        narcolepsy type 1 have low or undetectable levels of cerebrospinal fluid (CSF) hypocretin-1.
                        <sup>3,†</sup>
                      </p>
                      <p>
                        Based on animal models, loss of hypocretin neurons is thought to lead to inconsistent signaling of
                        wake-promoting neurons responsible for maintaining wakefulness and muscle tone and inhibit NREM
                        and REM-sleep promoting neurons (eg, dopaminergic, noradrenergic, serotonergic neurons).
                        <sup>1,7,‡</sup>
                      </p>
                      <ul className="narco-one-list">
                        <li className="narco-one-list-item">
                          <span>
                            Wake-promoting neurons fail to activate cortical and subcortical arousal regions and fail to
                            inhibit sleep-promoting neurons (ie, GABAergic neurons), resulting in{' '}
                            <SiteLink
                              to="/screening-and-diagnosis/recognizing-symptoms/"
                              anchor={`#${anchors.excessiveSleepiness}`}
                              className="link"
                              tracking="neurobiology of wakefulness, click, ess link - neurobiology"
                            >
                              excessive daytime sleepiness.
                            </SiteLink>
                            <sup>7</sup>
                          </span>
                        </li>
                        <li className="narco-one-list-item">
                          <span>
                            Certain wake-promoting neurons that also suppress REM signaling fail to inhibit REM-promoting
                            neurons, resulting in&nbsp;
                            <SiteLink
                              to="/screening-and-diagnosis/recognizing-symptoms/"
                              anchor={`#${anchors.cataplexy}`}
                              className="link"
                              tracking="neurobiology of wakefulness, click, cataplexy link"
                            >
                              cataplexy
                            </SiteLink>{' '}
                            and other abnormal manifestations of REM sleep such as{' '}
                            <SiteLink
                              to="/screening-and-diagnosis/recognizing-symptoms/"
                              anchor={`#${anchors.hallucinations}`}
                              className="link"
                              tracking="neurobiology of wakefulness, click, hypnagogic/hypnopompic halluciations link"
                            >
                              hypnagogic/hypnopompic
                            </SiteLink>{' '}
                            and{' '}
                            <SiteLink
                              to="/screening-and-diagnosis/recognizing-symptoms/"
                              anchor={`#${anchors.paralysis}`}
                              className="link"
                              tracking="neurobiology of wakefulness, click, sleep paralysis link"
                            >
                              sleep paralysis.
                            </SiteLink>
                            <sup>2,7</sup>
                          </span>
                        </li>
                        <li className="narco-one-list-item">
                          <span>
                            Disruption of mutually inhibitory sleep-wake circuits may also lead to unwanted transitions
                            from sleep to wakefulness and to associated{' '}
                            <SiteLink
                              to="/screening-and-diagnosis/recognizing-symptoms/"
                              anchor={`#${anchors.disruption}`}
                              className="link"
                              tracking="neurobiology of wakefulness, click, sleep disruption link"
                            >
                              sleep disruption.
                            </SiteLink>
                            <sup>2,8</sup>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <h3 className="narco-type" id="second-title">
                      Narcolepsy type 2 (narcolepsy without cataplexy){' '}
                    </h3>
                    <div className="narco-type-content">
                      Narcolepsy type 2 is likely a heterogeneous disorder.<sup>3</sup> In patients with narcolepsy type
                      2, cataplexy is not present, and CSF hypocretin-1 levels are usually normal or unknown.
                      <sup>3</sup> One quarter to one third of patients diagnosed with narcolepsy type 2 may actually have
                      hypocretin deficiency, which would classify them as narcolepsy type 1 if levels were known, and about 8% may have intermediate levels of CSF hypocretin-1.<sup>3,9</sup> These patients are more likely to develop cataplexy over time than those with normal hypocretin
                      levels.
                      <sup>9</sup> The exact cause of narcolepsy type 2 is unknown.
                      <sup>3</sup>
                    </div>
                  </div>
                  <div className="footnotes">
                    <div className="notes">
                      <div>*</div>
                      <span>
                        Not all patients with narcolepsy type 1 experience all 5 symptoms.<sup>3,10</sup>
                      </span>
                    </div>
                    <div className="notes">
                      <div>†</div><span>CSF hypocretin-1 levels typically are not obtained in clinical practice.</span>
                    </div>
                    <div className="notes">
                      <div>‡</div>
                      <span>
                        Based on animal models. Other neuronal systems are also thought to be involved.
                        <sup>1,7</sup>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <ReadNext readNext={readNext} />
            </div>
          </div>

          <ReferencesComponent referenceList={references} />
        </div>
      </Layout>
    )
  }
  isNarcoTypeOneSelected(typeSelected) {
    return typeSelected ? 'narco-type-header-selected' : 'narco-type-header'
  }
  isNarcoContentSelected(typeSelected) {
    return typeSelected ? 'narco-type-content-selected' : 'narco-type-content'
  }
  isNarcoArrowSelected(typeSelected) {
    return typeSelected ? 'arrow-right-selected' : 'arrow-right'
  }
}

export default Pathophysiology
