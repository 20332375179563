import React from 'react'
import PropTypes from 'prop-types'

import './read-next-button.scss'
import SiteLink from '../SiteLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

const ReadNext = props => {
  return (
    <SiteLink to={props.readNext.goTo} className="read-next-button gtm-click" tracking={props.readNext.tracking}><div className="read-next-content"><b>READ NEXT:&nbsp;</b> <div>{props.readNext.readNextContent}<FontAwesomeIcon icon={faAngleRight}/></div></div>
    </SiteLink>
  )
}

ReadNext.propTypes = {
  readNext: PropTypes.object.isRequired
}

export default ReadNext